<template>
  <div id="hero" v-scroll="handleScroll">
    <div class="container">
      <div class="content">
        <div class="title">
          A LiberFly é confiável?
        </div>
        <div class="description">
          Recentemente, nos deparamos com uma pergunta: <strong>a LiberFly é confiável?</strong> É natural que o consumidor tenha dúvidas quando lhe é oferecido um novo tipo de serviço no mercado. Entretanto, é dever da LiberFly explicar por que nosso serviços são, de fato, confiáveis.. Desde nossa fundação, em 2016, buscamos unicamente proteger o passageiro dos <strong>abusos cometidos pelas companhias aéreas</strong>.
        </div>
      </div>
    </div>
    <div class="bg-images">
      <img class="behindrocks" :style="{bottom: `-${scrollTop}px`}" src="@/assets/bg-behindrocks.svg" alt="Pedras e sol">
      <img class="frontrocks" src="@/assets/bg-frontrocks.svg" alt="Pedras">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTop: 0,
    };
  },
  methods: {
    handleScroll(e) {
      this.scrollTop = e.target.documentElement.scrollTop / 2;
    },
  },
};
</script>

<style lang="scss" scoped>
#hero {
  width: 100%;
  height: 726px;
  padding-top: 162px;
  position: relative;
  background: linear-gradient(
    180deg,
    $blue-primary 0%,
    $blue-secondary 54.17%,
    $blue-light 100%
  );
  overflow: hidden;

  @media (max-width: 576px) {
    padding-top: 56px;
    padding-bottom: 146px;
    height: auto;
  }

  .container {
    position: relative;
    height: 100%;

    .content {
      color: $white;

      .title {
        font-weight: $semibold;
        font-size: 42px;
        line-height: 51px;
        margin-bottom: 15px;
        max-width: 660px;

        @media (max-width: 576px) {
          font-size: 40px;
        }
      }

      .description {
        font-weight: $medium;
        font-size: 18px;
        line-height: 22px;
        color: $white-secondary;
        max-width: 586px;

        @media (max-width: 576px) {
          font-size: 14px;
        }

        strong {
          font-weight: $medium;
          color: $yellow;
        }
      }
    }
  }

  .bg-images {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;

    @media (max-width: 576px) {
      max-width: 320px;
    }

    .behindrocks {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 1103px;
      width: 100%;
    }

    .frontrocks {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 1209px;
      width: 100%;
    }
  }
}
</style>
