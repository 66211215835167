<template>
  <div class="trustable">
    <TrustableHero />
    <HomeNews />
    <TrustableContent />
    <Reports />
    <Footer :hasCall="false" />
  </div>
</template>

<script>
import TrustableHero from "@/components/Trustable/Hero.vue";
import TrustableContent from "@/components/Trustable/Content.vue";
import HomeNews from "@/components/Home/News.vue";
import Reports from "@/components/Reports.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    TrustableHero,
    TrustableContent,
    HomeNews,
    Reports,
    Footer,
  },
  jsonld: {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://www.liberfly.com.br/",
    name: "LiberFly | A LiberFly é confiável?",
    alternateName: "LiberFly é a maior empresa da América Latina | LiberFly",
    description:
      "A LiberFly é a maior empresa de Soluções de Problemas Aéreos da América Latina e busca a sua indenização sem custos ou burocracia",
    keywords:
      "voo,atrasado,cancelado,overbooking,direito,indenização,perdi,extraviada,avião,passagem,viagem,bagagem,danificada,aério,compensação,voo,no-show",
  },
  mounted() {
    const meta = document.querySelector('meta[name="robots"]');
    if (this.$route.meta.robots) {
      meta.setAttribute("content", this.$route.meta.robots);
    }
  },
};
</script>

<style lang="scss" scoped>
.trustable {
  background: $blue-primary;
  padding-top: 60px;
}
</style>
